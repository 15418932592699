import React from 'react'
import Helmet from 'react-helmet'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Home from '../containers/Home';
import BookATable from  '../containers/BookATable'
import ContactUs from '../containers/ContactUs'
import Gallery from  '../containers/Gallery'
import PricesAndMenus from '../containers/PricesAndMenus'

export const IndexPageTemplate = props => (
  <>
    <Helmet>
        <title>{props?.title}</title>
        <meta name="description" content={props?.description} />
    </Helmet>
    <Home {...props} classNames="is-default" />
    <PricesAndMenus />
    <BookATable />
    <Gallery items={props.gallery} />
    <ContactUs />
  </>
)

const IndexPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark

  return (
    <IndexPageTemplate {...frontmatter} />
  )
}

IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default IndexPage

export const pageQuery = graphql`
  query IndexPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        title
        description
        slides {
          title
          subtitle
          backgroundImage {
            childImageSharp {
              fluid(maxWidth: 2000, quality: 80) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        gallery {
          altText
          image {
            childImageSharp {
              fluid(maxWidth: 1200, quality: 80) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
`
